.container {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-self: center;
    gap: 3rem;
    width: fit-content;
    background-color: var(--utmb-color-grey);
    font-family: var(--utmb-font-oxanium);
    color: var(--utmb-color-black);
    overflow: auto;
    
    &>div {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 2rem;
    }

    h1, button {
        align-self: center;
    }
    ul {
        padding-left: 3rem;
    }
}
